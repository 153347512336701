<template>
	<div class="flex-column full-height bg-white position-relative">
		<div
			v-if="false"
			class="pa-10 justify-space-between"
		>
			<label class="box flex-1 pa-10">
				<input
					type="file"
					@change="setFile"
					accept=".xls,.xlsx"
					class="hidden"
				/>
				<template
					v-if="item.file"
				>
					{{ item.file.name}}
				</template>
				<template
					v-else
				>
					엑셀 파일을 선택하세요
				</template>
			</label>

			<button
				@click="getSearch"
				class="btn-inline btn-primary"
				:disabled="!item.file"
			>조회</button>
		</div>

		<div
			class="justify-space-between"
		>
			<input v-model="item_search.search_value" class="input-box flex-1" placeholder="검색어를 입력하세요" @keyup.enter="getSearch"/>
			<button
				@click="getSearch"
				class="bg-gray pa-10"
			><v-icon class="color-white">mdi-magnify</v-icon></button>
		</div>
		<div class="full-height  bg-gray-light position-relative overflow-y-auto">

			<div class="full-height">
				<ul
					v-if="items.length > 0"
				>
					<li
						v-for="(franchisee, f_index) in franchisee_list"
						:key="'franchisee_' + f_index"
						class=" bg-white mtb-10"
					>
						<div
							@click="onItem(franchisee)"
							class="pa-10 justify-space-between under-line cursor-pointer "
						>
							<div>{{ franchisee.account_id }}</div>
							<div>
								<button
									class="box bg-identify"
								><v-icon class="color-white">mdi-chevron-right</v-icon></button>
							</div>
						</div>

						<div class="pa-10 justify-space-between items-center">
							<span>휴대폰</span>
							<div>
								{{ franchisee.account_phone_number }}
								<label
									v-if="franchisee.is_sms_confirm == 1"
									class="label label-success"
								>인증 완료</label>
								<label
									v-else
									class="label label-danger"
								>미인증</label>
							</div>
						</div>

						<div class="pa-10 justify-space-between items-center">
							<span>PIN</span>
							<span>
								<label
									v-if="franchisee.is_pin == 1"
									class="label label-success"
								>설정 완료</label>
								<label
									v-else
									class="label label-danger"
								>미설정</label>
							</span>
						</div>

						<div class="pa-10 justify-space-between top-line-dashed pt-10 mt-10">
							<div>상점명</div>
							<div>{{ franchisee.shop_name }}</div>
						</div>

						<div class="pa-10 justify-space-between ">
							<div>대표</div>
							<div>{{ franchisee.shop_ceo }}</div>
						</div>

						<div class="pa-10 justify-space-between items-center">
							<span>상점 지갑</span>
							<span
								v-if="franchisee.virtual_account"
							>{{ franchisee.virtual_account_name }}</span>
							<span
								v-else
							><label class="label label-danger">{{ franchisee.virtual_account_name }}</label></span>
						</div>
					</li>
				</ul>
				<Empty
					v-else
				></Empty>
			</div>

			<div
				v-if="false"
			>
				<div class="overflow-x-auto">
					<table
						class="table table-even"
					>
						<colgroup>
							<col width="40px" />
							<col width="180px" />
							<col width="150px" />
							<col width="150px" />
							<col width="250px" />
						</colgroup>
						<thead>
							<tr>
								<th><input v-model="item_all" type="checkbox" /></th>
								<th>{{ account_list[0].B}}</th>
								<th>{{ account_list[0].N}}</th>
								<th>{{ account_list[0].O}}</th>
								<th>비고</th>
							</tr>
						</thead>
						<tbody
							v-for="(item, index) in account_list"
							:key="'item_' + index"
						>
							<tr
								v-if="index > 0"
								:class="{ 'bg-red': item.is_disabled}"
							>
								<td><input
									v-if="!item.is_disabled"
									v-model="item.is_all" type="checkbox" :disabled="item.is_disabled" :checked="item.is_all"
								/></td>
								<td class="td-left">{{ item.B }}</td>
								<td>{{ item.N }}</td>
								<td>{{ item.O }}</td>
								<td>{{ item.info }}</td>
							</tr>
						</tbody>
					</table>

				</div>
				<div class="mt-10">
					<button
						@click="postAccount"
						class="btn btn-primary"
					>선택 등록</button>
				</div>
			</div>
		</div>

		<FranchiseeItem
			v-if="is_on_item"
			:franchisee_info="item_franchisee"

			@click="offAdd"
			@cancel="offItem"
		></FranchiseeItem>

		<div

			class="position-absolute flex-column"
			style="right: 20px; bottom: 10px; z-index: 1"
		>
			<button
				@click="onItem"
			><v-icon large class="color-blue">mdi-plus-circle</v-icon></button>
		</div>
	</div>
</template>

<script>
import Empty from "../Layout/Empty";
import FranchiseeItem from "./FranchiseeItem";

export default {
	name: 'FranchiseeList'
	, components: {FranchiseeItem, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '가맹점 목록'
				, top: false
				, title: true
				, bottom: true
			}
			, items: []
			, item: {}
			, items_file: []
			, items_account: []
			, is_on_item: false
			, item_search: {
				page: 1
				, list_cnt: 10
				, search_value: ''
			}
			, item_franchisee: {

			}
		}
	}
	, computed: {
		account_list: function(){
			let numbers = []
			return this.items_file.filter( (item) => {
				if(item.O.indexOf('010-') == 0){

					item.is_phone = true
					numbers.filter(( number ) => {
						if(number == item.O){
							item.info = '중복된 연락처입니다.'
							item.is_phone = false
						}
					})
					if(item.is_phone) {
						numbers.push(item.O)
					}
				}else{
					item.info = '사용 불가능한 연락처입니다.'
				}
				this.items_account.filter( (account) => {
					if(item.B == account.account_id){
						item.is_account = true
						item.is_all = false
					}else{
						item.is_account = false
						item.is_all = true
					}
				})

				if(!item.is_phone){
					item.is_disabled = true
				}

				return item
			})
		}
		, items_account_request: function(){
			let t = []
			this.items_file.filter( (item) => {
				if(item.is_all){
					let data = {
						name: item.N
						, shop_name: item.B
					}
					t.push(data)
				}
			})
			return t
		}
		, franchisee_list: function(){
			return this.items.filter( (item) => {

				if(item.virtual_account){
					item.virtual_account_name = item.virtual_account
				}else{
					item.virtual_account_name = '미발급'
				}
				return item
			})
		}
	}
	, methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'member/getFranchiseeList'
					,data: {
						search_value: this.item_search.search_value
					}
				})

				if(result.success){
					this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postJoin: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.post_franchisee_excel
					,data: {
						file: this.item.file
					}
					, multipart: true
				})

				if(result.success){
					this.items_file = result.data.file_data
					this.items_account = result.data.account_data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setFile: function(e){

			let file = e.target.files[0]

			this.$set(this.item, 'file', file)
		}
		, postAccount: async function(){
			console.log(this.items_account_request)
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.post_franchisee_account
					,data: {
						list: JSON.stringify(this.items_account_request)
					}
				})

				if(result.success){
					this.item = {}
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onItem: function(item){
			this.is_on_item = true
			if(item){
				this.item_franchisee = item
			}
		}
		, offItem: function(){
			this.is_on_item = false
		}
		, offAdd: function(){
			this.offItem()
			this.getData()
		}
		, getSearch: function(page = 1){
			if(page){
				this.item_search.page = page
			}
			this.getData()
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
	, watch: {

	}
}
</script>